import Vue from 'vue'
// eslint-disable-next-line import/order
import App from './App.vue' // this has to be loaded first or else semantic doesn't work

import jquery from 'jquery'
import VueTippy, { TippyComponent } from 'vue-tippy'

import store from './store'
import router from './router'
import { Auth0Plugin } from './auth'
import Validation from './extensions/validation'
import 'vue-tippy/node_modules/tippy.js/themes/light.css'

global.$ = jquery
global.jQuery = jquery

require('../semantic/dist/semantic.min')
require('../node_modules/tippy.js/themes/light.css')

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: (process.env.VUE_APP_DB_ENDPOINT || '').slice(0, -4),
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
})
Vue.use(Validation)

Vue.use(VueTippy, {
  animationFill: false,
  animation: 'fade',
  theme: 'light',
  placement: 'bottom',
})
Vue.component('Tippy', TippyComponent)

Vue.config.productionTip = false

/**
 * Links identifying information about user so that we know who they are in FullStory
 */
function setupFullStory() {
  const { currentUser, account } = store.state
  if (currentUser) {
    FS.identify(currentUser.id, {
      displayName: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      // custom variables must follow format laid out in support article
      // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
      organizationId_int: currentUser.organizationId,
    })
  }

  if (account) {
    FS.setUserVars({
      accountStatus_str: account.status,
    })
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    setupFullStory()
    next()
  } catch {
    next()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
