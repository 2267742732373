import { getInstance } from '@/auth/authWrapper'

export class ApiError {
  constructor(response, error) {
    this.type = error.type
    this.code = error.code || response.status
    this.message = error.message
    this.response = response
  }
}

/**
 * @param {object} query - a dictionary of query parameters
 * @returns {string} - query string for a URI
 */
function buildQueryString(query) {
  if (!query) {
    return ''
  }

  const queryString = new URLSearchParams()

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryString.append(key, item)
      })
    } else {
      queryString.append(key, value)
    }
  })

  return `?${queryString.toString()}`
}

class Api {
  // this method doesn't use "this" but it's a natural place to manage instance
  // level state (e.g. a csrf token) so we leave it as a class method
  // eslint-disable-next-line class-methods-use-this
  async fetch(method, path, { body, query, ...params }) {
    const token = await getInstance().getTokenSilently()

    const response = await fetch(`${path}${buildQueryString(query)}`, {
      ...params,
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    const responseBody = await response.json()

    if (responseBody.err) {
      throw new ApiError(response, responseBody.err)
    }

    return responseBody
  }

  get = (url, query, params) => this.fetch('GET', url, { query, ...params })

  post = (url, body, params) => this.fetch('POST', url, { body, ...params })

  put = (url, body, params) => this.fetch('PUT', url, { body, ...params })

  patch = (url, body, params) => this.fetch('PATCH', url, { body, ...params })

  delete = (url, params) => this.fetch('DELETE', url, { ...params })
}

export const api = new Api()
