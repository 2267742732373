import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '@/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/moments',
    name: 'moments',
    component: () => import('@/views/v2/moments.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/moments/:id',
    name: 'moment',
    component: () => import('@/views/v2/moment/index.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/people',
    name: 'people',
    component: () => import('@/views/v2/people.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/people/:id',
    name: 'profile',
    component: () => import('@/views/v2/profile/index.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/add_person/:id',
    name: 'add_person',
    component: () => import('@/views/v2/add_person/index.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/import',
    name: 'people_import',
    component: () => import('@/views/v2/import/index.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/import/slack',
    name: 'import_slack',
    component: () => import('@/views/v2/import/import_slack.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/v2/groups.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/groups/:id',
    name: 'group',
    component: () => import('@/views/v2/group.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/relationships',
    name: 'relationships',
    component: () => import('@/views/v2/relationships.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/relationships/:id',
    name: 'relationship',
    component: () => import('@/views/v2/relationship.vue'),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/v2/settings/index.vue'),
    beforeEnter: authGuard,
  },
  // this path as written is needed for an auth0 redirect
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('@/views/v2/sign_in.vue'),
    beforeEnter: authGuard,
    alias: ['/sign_up', '/sign-up', '/sign-in'],
  },
  {
    path: '/sign-up/tutorial',
    name: 'tutorial',
    component: () => import('@/views/v2/sign_up_tutorial.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/sign-up/onboarding',
    name: 'sign_up_onboarding',
    component: () => import('@/views/v2/sign_up_onboarding.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/v2/templates.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/v2/activity.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/import/hris',
    name: 'import_hris',
    component: () => import('@/views/v2/import/import_hris.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/v2/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    const position = {}
    if (to.hash) {
      position.selector = to.hash
      return position
    }
    return { x: 0, y: 0 }
  },
})

export default router
