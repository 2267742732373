<template>
  <div id="app">
    <nav-bar v-if="showNav" :user="currentUser" />
    <router-view class="app-frame" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import navBar from '@/components/v2/navbar/navbar.vue'

export default {
  components: {
    navBar,
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
    }),
    showNav() {
      return ![
        'sign_in', // underscore is not a typo, required for auth0
        'sign_up_onboarding',
        'tutorial',
        '404',
        null,
      ].includes(this.$route.name)
    },
  },
}
</script>

<style lang="less">
@import '../semantic/dist/semantic.min.css';
@import './assets/less/message-content.less';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .app-frame {
    padding-bottom: 1em;
  }
}

.ProseMirror {
  min-height: @text-editor-height;
  outline: none;
}
</style>
