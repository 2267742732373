import {
  ValidationProvider,
  ValidationObserver,
  configure,
  extend,
} from 'vee-validate/dist/vee-validate.full.esm'

configure({
  classes: {
    invalid: 'error',
  },
  mode: 'eager',
})

extend('supported', {
  validate: (value) => {
    // Removed # from the standard set to support channel
    const unsupportedCharacters = /[\\"<>%{}|^~`[\]]/g
    return !unsupportedCharacters.test(value)
  },
  message: 'Please remove unsupported characters (\\ " < > % { } | ^ ~ [ ] `)',
})

extend('lowercase', {
  validate: (value) => value === value.toLowerCase(),
  message: 'Only lowercase characters are supported',
})

export default {
  install(Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  },
}
