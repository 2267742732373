// ==========================================
// Author: @johnwetzel
// Purpose: A mixin to help customer communication functions
// ==========================================

export default {
  methods: {
    openZendeskWidget() {
      window.zE('webWidget', 'open')
    },
    emailGatherHelp(subjectLine, body) {
      const urlReadySubject = subjectLine.replace(/\s/g, '%20')
      const urlReadyBody = body.replace(/\s/g, '%20')
      window.location.assign(
        `mailto:help@teamgather.co?subject=${urlReadySubject}&body=${urlReadyBody}`
      )
    },
    /**
     * Opens the people info spreadsheet template that can be copied.
     */
    openPeopleInfoSpreadsheetTemplate() {
      window.open(
        'https://docs.google.com/spreadsheets/d/1Sag1-AfUb8XU5YszesOv4wTEMkAskw8F903_ZkFLS6I/template/preview',
        '_blank'
      )
    },
    /**
     * Links them to join our Slack Community
     */
    linkToJoinCommunity() {
      window.open('https://peoplepeople.meetsy.io/', '_blank')
    },
    bookDemo() {
      window.open('https://www.teamgather.co/request-early-access', '_blank')
    },
    openFinchHelpPage() {
      window.open(
        'https://www.notion.so/teamgather/HR-Integrations-Overview-c71551e799394d37a0ad667f81bb648e',
        '_blank'
      )
    },
    openSlackCustomSenderHelpPage() {
      window.open(
        'https://www.notion.so/teamgather/Setup-a-Custom-App-Name-Icon-0d731e5f47104416ba230cbc5ce32782#11416121261640feb15574c12c63c2d7',
        '_blank'
      )
    },
  },
}
