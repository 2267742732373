import jquery from 'jquery'
import { ApiError } from './api'

const TOAST_DEFAULT_OPTIONS = {
  position: 'top center',
  displayTime: 'auto',
  minDisplayTime: 5000,
  closeIcon: true,
  showProgress: 'bottom',
  newestOnTop: true,
}

const DEFAULT_ERROR_MESSAGE = 'Something went wrong...'

const DEFAULT_HTTP_TOASTS = {
  400: 'Bad request. Please contact us',
  401: 'Try logging in again',
  403: "You don't have permission to access this",
  404: "👀ps! We can't find what you're looking for",
  408: 'Try refreshing the page',
  410: "Contact us about what you're looking for",
  500: 'We goofed! If this continues, contact us',
}

export class ToastDispatcher {
  // eslint-disable-next-line class-methods-use-this
  toast(options) {
    if (jquery('.ui.toast').toast('get toasts').length > 0) {
      return
    }

    jquery('body').toast({
      ...TOAST_DEFAULT_OPTIONS,
      ...options,
    })
  }

  error(error) {
    let message
    if (!(error instanceof ApiError)) {
      message = DEFAULT_ERROR_MESSAGE
    } else if (!error.message) {
      message = DEFAULT_HTTP_TOASTS[error.code] || `${error.code} ${error.type}`
    } else {
      message = error.message
    }

    this.toast({ title: 'Error', message, class: 'error' })
  }

  success(message) {
    this.toast({ title: 'Success', message, class: 'success' })
  }

  info(message) {
    this.toast({ title: 'Note', message, class: 'info' })
  }
}

export const toast = new ToastDispatcher()
