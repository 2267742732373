<template>
  <img :class="classes" :src="src" :alt="alt" v-on="$listeners" />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        image: true,
        disabled: this.disabled,
        fluid: this.fluid,
        rounded: this.rounded,
        circular: this.circular,
        centered: this.centered,
        avatar: this.avatar,
        ...(this.size ? { [this.size]: true } : {}),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ui.image {
  flex-shrink: 0;
}
</style>
