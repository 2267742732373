<template>
  <router-link :to="to" :class="classes">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        item: true,
        active: this.active,
      }
    },
  },
}
</script>
