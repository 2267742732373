<template>
  <div class="flex" :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'baseline',
          'center',
          'start',
          'end',
          'flex-start',
          'flex-end',
          'stretch',
        ].includes(value),
    },
    direction: {
      type: String,
      default: 'row',
      validator: (value) =>
        ['column', 'row', 'column-reverse', 'row-reverse'].includes(value),
    },
    justify: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'center',
          'start',
          'end',
          'flex-start',
          'flex-end',
          'space-between',
          'space-around',
          'space-evenly',
          'stretch',
        ].includes(value),
    },
    wrap: {
      type: String,
      default: 'no-wrap',
      validator: (value) => ['wrap', 'no-wrap', 'wrap-reverse'].includes(value),
    },
    gap: {
      type: String,
      default: '0',
      validator: (value) =>
        ['px', 'rem', 'em'].some((suffix) => value.includes(suffix)) ||
        value === '0',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        'flex-direction': this.direction,
        ...(this.align ? { 'align-items': this.align } : {}),
        ...(this.justify ? { 'justify-content': this.justify } : {}),
        'flex-wrap': this.wrap,
        gap: this.gap,
        ...(this.fluid ? { width: '100%' } : {}),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: normal;
  justify-content: normal;
}
</style>
