<template>
  <div class="sticky-container elevated">
    <banner v-if="(account || {}).status === 'trial'">
      🧐 See how other companies use Gather
      <btn class="banner-cta" @click.native="bookDemo"> Schedule Demo </btn>
    </banner>
    <banner v-if="(account || {}).status === 'trial_ended'" variant="warning">
      😞 Your free trial has ended
      <btn class="banner-cta" @click.native="openZendeskWidget">
        Contact Us
      </btn>
    </banner>
    <banner v-if="(account || {}).status === 'churned'" variant="warning">
      😞 Your subscription has ended
      <btn class="banner-cta" @click.native="openZendeskWidget">
        Contact Us
      </btn>
    </banner>
    <navbar-container>
      <logo />
      <left-tab :to="{ name: 'moments' }" :active="$route.name === 'moments'">
        Moments
      </left-tab>
      <left-tab
        :to="{ name: 'templates' }"
        :active="$route.name === 'templates'"
      >
        Templates
      </left-tab>
      <left-tab :to="{ name: 'activity' }" :active="$route.name === 'activity'">
        Activity
      </left-tab>
      <left-tab
        :to="{ name: 'people', hash: '#active' }"
        class="ui simple dropdown item"
      >
        People
        <i class="dropdown icon" />
        <g-menu class="elevated">
          <menu-item
            :to="{ name: 'people', hash: '#active' }"
            :active="$route.name === 'people'"
          >
            Everyone
          </menu-item>
          <menu-item
            :to="{ name: 'groups' }"
            :active="$route.name === 'groups'"
          >
            Groups
          </menu-item>
          <menu-item
            :to="{ name: 'relationships' }"
            :active="$route.name === 'relationships'"
          >
            Relationships
          </menu-item>
        </g-menu>
      </left-tab>

      <g-menu right :style="{ background: 'inherit' }">
        <right-tab href="https://www.teamgather.co/help" tooltip="Help Center">
          <i class="fas fa-question-circle" />
        </right-tab>
        <right-tab :to="{ name: 'settings' }" tooltip="Settings">
          <i class="fas fa-cog" />
          <g-label v-if="(account || {}).status === 'trial'" pink size="tiny">
            TRIAL
          </g-label>
        </right-tab>
        <right-tab
          v-if="user"
          :to="{ name: 'profile', params: { id: user.id } }"
          tooltip="Profile"
        >
          <g-image :src="currentUserAvatar" alt="Profile picture" avatar />
        </right-tab>
      </g-menu>
    </navbar-container>
  </div>
</template>

<script>
import customerCommunicationsMixin from '@/mixins/customerCommunicationsMixin'
import defaultAvatar from '@/assets/img/profile_avatar_small.png'
import gLabel from '@/components/v2/label.vue'
import gImage from '@/components/v2/image.vue'
import gMenu from '@/components/v2/menu/menu.vue'
import menuItem from '@/components/v2/menu/item.vue'
import btn from '@/components/v2/btn.vue'
import { api } from '@/api'
import { toast } from '@/toasts'

import banner from './banner.vue'
import logo from './logo.vue'
import navbarContainer from './navbar_container.vue'
import leftTab from './left_tab.vue'
import rightTab from './right_tab.vue'

export default {
  components: {
    banner,
    btn,
    navbarContainer,
    gLabel,
    gMenu,
    menuItem,
    logo,
    leftTab,
    rightTab,
    gImage,
  },
  mixins: [customerCommunicationsMixin],
  props: {
    user: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      account: null,
      organization: null,
      defaultAvatar,
    }
  },
  computed: {
    currentUserAvatar() {
      return (this.user || {}).avatar || defaultAvatar
    },
  },
  watch: {
    user: {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    setupZendesk() {
      window.zE('webWidget', 'prefill', {
        name: {
          value: `${this.user.firstName} ${this.user.lastName}`,
        },
        email: {
          value: this.user.email,
        },
      })
    },
    async getData() {
      const { organizationId } = this.user || {}
      if (organizationId) {
        try {
          this.loading = true
          this.organization = (
            await api.get(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${organizationId}`
            )
          ).data
          if (this.organization.accountId) {
            this.account = (
              await api.get(
                `${process.env.VUE_APP_DB_ENDPOINT}/v2/accounts/${this.organization.accountId}`
              )
            ).data
          }
        } catch (error) {
          toast.error(error)
        } finally {
          this.loading = false
        }

        if (this.user.email) {
          this.setupZendesk()
        }
      }
    },
  },
}
</script>

<style scoped>
.sticky-container {
  position: sticky;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .sticky-container {
    position: relative;
    top: auto;
  }
}

.elevated {
  z-index: 900;
}

.banner-cta {
  margin-left: 1em !important;
}
</style>
