<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
import jquery from 'jquery'

export default {
  props: {
    borderless: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    labeledIcon: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 0,
    },
    right: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'mini',
          'tiny',
          'small',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    stackable: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    four: {
      type: Boolean,
      default: false,
    },
    five: {
      type: Boolean,
      default: false,
    },
    six: {
      type: Boolean,
      default: false,
    },
    seven: {
      type: Boolean,
      default: false,
    },
    eight: {
      type: Boolean,
      default: false,
    },
    nine: {
      type: Boolean,
      default: false,
    },
    ten: {
      type: Boolean,
      default: false,
    },
    eleven: {
      type: Boolean,
      default: false,
    },
    twelve: {
      type: Boolean,
      default: false,
    },
    thirteen: {
      type: Boolean,
      default: false,
    },
    fourteen: {
      type: Boolean,
      default: false,
    },
    fifteen: {
      type: Boolean,
      default: false,
    },
    sixteen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        menu: true,
        borderless: this.borderless,
        compact: this.compact,
        fixed: this.fixed,
        'labeled icon': this.labeledIcon,
        left: this.left,
        right: this.right,
        secondary: this.secondary,
        ...(this.size ? { [this.size]: true } : {}),
        stackable: this.stackable,
        sticky: this.sticky,
        top: this.top,
        vertical: this.vertical,
        'one item': this.one,
        'two item': this.two,
        'three item': this.three,
        'four item': this.four,
        'five item': this.five,
        'six item': this.six,
        'seven item': this.seven,
        'eight item': this.eight,
        'nine item': this.nine,
        'ten item': this.ten,
        'eleven item': this.eleven,
        'twelve item': this.twelve,
        'thirteen item': this.thirteen,
        'fourteen item': this.fourteen,
        'fifteen item': this.fifteen,
        'sixteen item': this.sixteen,
      }
    },
  },
  mounted() {
    this.syncJquery()
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    syncJquery() {
      if (this.sticky) {
        jquery(this.$el).sticky({
          offset: this.offset,
        })
      }
    },
  },
}
</script>
