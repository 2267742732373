<template>
  <button
    v-tippy
    :content="tooltip"
    :class="classes"
    @show="() => !!tooltip && !!computedIcon"
  >
    <i v-if="computedIcon" :class="[computedIcon, 'bordered']" />
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    basic: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    tooltip: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'positive', 'negative', 'default'].includes(
          value
        ),
    },
    dismiss: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedIcon() {
      if (this.dismiss) {
        return 'fas fa-times'
      }

      return this.icon
    },
    computedVariant() {
      if (this.dismiss) {
        return 'secondary'
      }

      return this.variant
    },
    classes() {
      return {
        ui: true,
        button: true,
        basic: this.basic,
        circular: this.circular,
        compact: this.compact,
        disabled: this.disabled || this.loading,
        fluid: this.fluid,
        icon: !!this.icon,
        inverted: this.inverted,
        loading: this.loading,
        ...(this.computedVariant !== undefined
          ? { [this.computedVariant]: true }
          : {}),
        ...(this.size ? { [this.size]: true } : {}),
        ...(this.dismiss ? { dismiss: true, icon: true } : {}),
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';

.ui.icon.button {
  .bordered {
    width: 1em;
    padding: 0 !important;
    box-shadow: none;
  }

  &.primary {
    color: @blue;
    background: @white;
  }

  &.secondary {
    color: @dark-grey;
    background: @white;
  }

  &.dismiss {
    color: @dark-grey;
    background: transparent;
  }

  &.dismiss:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}

.ui.icon.button:hover {
  background: @light-grey-hover;
}
</style>
