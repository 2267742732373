<template>
  <div v-if="!dismissed" :class="bannerClasses">
    <flex justify="space-between">
      <span class="bumper" />
      <span class="banner-content">
        <slot />
      </span>
      <span class="bumper">
        <btn
          tooltip="Dismiss"
          dismiss
          @click.native="() => (dismissed = true)"
        />
      </span>
    </flex>
  </div>
</template>

<script>
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'

export default {
  components: { btn, flex },
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'warning'].includes(value),
    },
  },
  data() {
    return {
      dismissed: false,
    }
  },
  computed: {
    bannerClasses() {
      return {
        banner: true,
        [this.variant]: true,
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/responsive.less';

.banner {
  position: sticky;
  top: 0;
  padding: 1em;
  color: @blue !important;
  background-color: @light-blue;
}

.warning {
  background-color: @yellow !important;
}

.banner-content {
  width: 100%;
  text-align: center;
}

.bumper {
  width: 2em;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .banner {
    position: relative;
    top: auto;
  }
}
</style>
