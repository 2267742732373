<template>
  <div :class="classes">
    <i v-if="icon" :class="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    attached: {
      type: String,
      default: null,
      validator: (value) =>
        [
          'top',
          'right',
          'bottom',
          'left',
          'top right',
          'bottom right',
          'top left',
          'bottom left',
          null,
        ].includes(value),
    },
    basic: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    red: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    lightGreen: {
      type: Boolean,
      default: false,
    },
    pink: {
      type: Boolean,
      default: false,
    },
    yellow: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    lightBlue: {
      type: Boolean,
      default: false,
    },
    lightOrange: {
      type: Boolean,
      default: false,
    },
    lightPurple: {
      type: Boolean,
      default: false,
    },
    lightTeal: {
      type: Boolean,
      default: false,
    },
    verticallyCentered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        ...(this.attached ? { [`${this.attached} attached`]: true } : {}),
        basic: this.basic,
        circular: this.circular,
        floating: this.floating,
        horizontal: this.horizontal,
        ...(this.size ? { [this.size]: true } : {}),
        red: this.red,
        green: this.green,
        'light-green-background': this.lightGreen,
        'pink-background': this.pink,
        'yellow-background': this.yellow,
        blue: this.blue,
        'light-blue-background': this.lightBlue,
        'light-orange-background': this.lightOrange,
        'light-purple-background': this.lightPurple,
        'light-teal-background': this.lightTeal,
        label: true,
        'vertically-centered': this.verticallyCentered,
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.light-green-background,
.pink-background,
.yellow-background,
.light-blue-background,
.light-orange-background,
.light-purple-background,
.light-teal-background {
  color: @blue !important;
}

.vertically-centered {
  vertical-align: middle !important;
}
</style>
