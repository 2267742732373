import Vue from 'vue'
import Vuex from 'vuex'

import { api } from '../api'
import { toast } from '../toasts'
import { getInstance } from '../auth/authWrapper'

// only mutation type we maintain
const SET_CURRENT_USER = 'SET_CURRENT_USER'

Vue.use(Vuex)

async function logout() {
  ;(await getInstance()).logout({
    returnTo: `${window.location.origin}/sign_in`,
  })
}

export default new Vuex.Store({
  state: {
    currentUser: null,
    organizationId: 0,
  },
  mutations: {
    [SET_CURRENT_USER](state, user) {
      state.currentUser = user
      state.organizationId = (user && user.organizationId) || 0
    },
  },
  actions: {
    async findOrCreateUser({ commit }, payload) {
      let user = null
      try {
        user = (
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/auth0/update-or-create-user`,
            payload
          )
        ).data
      } catch (error) {
        if (error.type !== 'NotFound') {
          toast.error(error)
        }
        // we set a timeout so there's enough time to read the toast
        setTimeout(logout, 3000)
      }
      commit('SET_CURRENT_USER', user)
      return user
    },
    async findUserByEmail({ commit }, email) {
      let user = null
      try {
        ;[user] = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/users`, {
            email,
            status: ['active', 'onboarding'],
          })
        ).data
      } catch (error) {
        if (
          error.type !== 'NotFound' &&
          error.type !== 'Unauthorized' &&
          error.type !== 'Unauthenticated'
        ) {
          toast.error(error)
        }
      }
      commit('SET_CURRENT_USER', user)
      return user
    },
  },
  strict: process.env.NODE_ENV !== 'production',
})
