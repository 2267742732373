<template>
  <router-link :to="{ name: 'moments' }" class="item logo">
    <g-image :src="gatherG" alt="Gather logo" />
  </router-link>
</template>

<script>
import gatherG from '@/assets/img/gather_g_small.png'
import gImage from '@/components/v2/image.vue'

export default {
  components: { gImage },
  data() {
    return { gatherG }
  },
}
</script>

<style scoped>
.logo {
  /* negative margin compensates for padding baked into the png image */
  margin-left: -2em;
}

.logo:hover {
  background: none !important;
}
</style>
