<template>
  <a
    v-if="href"
    v-tippy="{ placement: 'bottom' }"
    target="_blank"
    :href="href"
    :content="tooltip"
    class="item"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-tippy="{ placement: 'bottom' }"
    :to="to"
    class="item"
    :content="tooltip"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    href: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
}
</script>
