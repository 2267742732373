import { getInstance } from './authWrapper'
import store from '../store'

async function getCurrentUser(authService) {
  if (store.state.currentUser) {
    return store.state.currentUser
  }

  let user = await store.dispatch('findUserByEmail', authService.user.email)

  if (!user) {
    user = await store.dispatch('findOrCreateUser', authService.user)
  }

  return user
}

async function getAuthStatus(authService) {
  if (authService.isAuthenticated) {
    const user = await getCurrentUser(authService)
    if (user && user.organizationId) {
      return 'authenticated'
    }

    if (user && !user.organizationId) {
      return 'authenticated_no_org'
    }

    return 'authenticated_no_user'
  }

  if (window.location.href.includes('sign_in')) {
    return 'unauthenticated_sign_in'
  }

  return 'unauthenticated_sign_up'
}

async function guard(authService, to, from, next) {
  const status = await getAuthStatus(authService)
  if (status === 'authenticated') {
    next()
  } else if (status === 'authenticated_no_org') {
    if (to.name !== 'sign_up_onboarding') {
      next({ name: 'sign_up_onboarding' })
    } else {
      next()
    }
  } else if (status === 'authenticated_no_user') {
    if (to.name !== null && from.name !== null) {
      authService.logout({
        returnTo: `${window.location.origin}/sign_in`,
      })
    } else if (to.name !== 'sign_in') {
      next({ name: 'sign_in' })
    } else {
      next()
    }
  } else if (status === 'unauthenticated_sign_in') {
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      fragment: 'show_sign_in',
    })
    next(false)
  } else if (status === 'unauthenticated_sign_up') {
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    next(false)
  }
}

export default async (to, from, next) => {
  const authService = getInstance()

  // If loading has already finished, check our auth state
  if (authService.loading === false) {
    await guard(authService, to, from, next)
  }

  // Watch for the loading property to change before we check auth status
  authService.$watch('loading', async (loading) => {
    if (loading === false) {
      await guard(authService, to, from, next)
    }
  })
}
